import { Button, Grid, List, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getComments } from "../../../api";
import Body from "../../Body/Body";
import DeleteButton from "./DeleteButton.js/DeleteButton";

export default function Commentlist(props) {
  const [comments, setComments] = useState([]);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function getCommentFromCourse() {
      try {
        let { data } = await getComments(location.pathname.split("/")[2]);
        setComments(data);
      } catch (err) {
        history.push("/404");
      }
    }
    getCommentFromCourse();
  }, []);

  return (
    <List>
      {comments.map((comment) => {
        return (
          <Paper
            key={comment._id}
            style={{ padding: "40px 20px", marginTop: 10 }}
          >
            <Grid container wrap="nowrap" spacing={2}>
              <Grid justifycontent="left" item xs zeroMinWidth>
                <h4 style={{ margin: 0, textAlign: "left" }}>
                  {comment.username}
                </h4>
                <DeleteButton
                  variant="contained"
                  color="secondary"
                  commentId={comment._id}
                >
                  Delete
                </DeleteButton>
                <Body content={comment.content}></Body>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </List>
  );
}
