import React, { useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import * as api from "../../api/index";

import * as actionType from "../../constants/actionTypes";
import useStyles from "./styles";

const NewSubject = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [newSubject, setNewSubject] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    history.push("/");

    setUser(null);
  };

  const handleClick = async () => {
    try {
      await api.createSubject({ name: newSubject });
      history.go(`/`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location, user?.token]);

  return (
    <div position="static" color="inherit">
      {user?.result ? (
        <div>
          <h1>Add a subject</h1>
          <div className={classes.profile}>
            <TextField
              onChange={(e) => setNewSubject(e.target.value)}
            ></TextField>
            <Button
              size="small"
              variant="contained"
              className={classes.logout}
              color="primary"
              onClick={handleClick}
            >
              Enter
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Redirect to="/"></Redirect>
        </div>
      )}
    </div>
  );
};

export default NewSubject;
