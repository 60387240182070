import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import * as api from "../../api/index";
import Body from "../Body/Body";
import EditButton from "./EditButton/EditButton";
import Comment from "../Comments/Comment";
import { useHistory } from "react-router-dom";

export default function Course(props) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const history = useHistory();

  useEffect(() => {
    async function getCourse() {
      try {
        const { data } = await api.getCourse(
          props.match.params.subject,
          props.match.params.id
        );
        setTitle(data.title);
        setContent(data.content);
      } catch (err) {
        history.push("/404");
      }
    }

    getCourse();
  }, [title, history]);

  return (
    <React.Fragment>
      <Header title={title}></Header>
      <EditButton></EditButton>
      <hr />
      <Body content={content}></Body>
      <hr />
      <Comment></Comment>
    </React.Fragment>
  );
}
