import React from "react";
import PropTypes from "prop-types";

import one from "./1.gif";
import two from "./2.gif";
import three from "./3.gif";
import four from "./4.gif";
import five from "./5.gif";
import six from "./6.gif";
import seven from "./7.gif";

export default function Homepagebody(props) {
  const imageList = [one, two, three, four, five, six, seven];

  const randomIndex = Math.floor(Math.random() * imageList.length);

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>

      <figure
        style={{
          display: "table",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "5%",
        }}
      >
        <img
          src={imageList[randomIndex]}
          style={{
            width: "500px",
          }}
        />
        <figcaption
          style={{
            display: "table-caption",
            captionSide: "top",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          And I don't know what to put on my homepage
        </figcaption>
      </figure>
    </div>
  );
}
