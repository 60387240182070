import React, { useState } from "react";
import Commentlist from "./CommentList/CommentList";
import ReactQuill from "react-quill";
import { Button, TextField } from "@material-ui/core";
import { createComment } from "../../api";
import { useHistory, useLocation } from "react-router";

export default function Comment(props) {
  const [content, setContent] = useState("");
  const [username, setUsername] = useState("");
  const location = useLocation();
  const history = useHistory();

  const handleSubmit = async () => {
    const courseId = location.pathname.split("/")[2];
    try {
      await createComment(courseId, { username, content });
      history.go(0);
    } catch (err) {
      console.log("EWWW");
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  return (
    <React.Fragment>
      <h3>Comments</h3>
      <Commentlist></Commentlist>
      <TextField
        id="standard-basic"
        label="Name"
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      />
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={content}
        onChange={setContent}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={username === "" && content === ""}
      >
        Submit
      </Button>
    </React.Fragment>
  );
}
