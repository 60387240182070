import React from "react";
// import React, { useEffect, useState } from 'react'
// import { useLocation } from 'react-router'
// import { getCourses } from '../../api'
// import Body from '../Body/Body'
import Header from "../Header/Header";
import { Courselist } from "./CourseList";
import SubjectButton from "./SubjectButton/SubjectButton";

const Subject = (props) => {
  return (
    <React.Fragment>
      <Header title={props.match.params.subject}></Header>
      <SubjectButton></SubjectButton>
      <hr />
      <Courselist subject={props.match.params.subject}></Courselist>
    </React.Fragment>
  );
};

export default Subject;
