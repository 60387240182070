import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";

import * as actionType from "../../../../constants/actionTypes";
import { deleteComment } from "../../../../api";
import useStyles from "./styles";

const DeleteButton = (props) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    history.push("/");
    setUser(null);
  };

  const handleClick = () => {
    try {
      const { data } = deleteComment(props.commentId);
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  return (
    <div position="static" color="inherit">
      {user?.result ? (
        <div>
          <div className={classes.profile}>
            <Button
              size="small"
              variant="contained"
              className={classes.logout}
              color="secondary"
              onClick={handleClick}
            >
              Delete
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default DeleteButton;
