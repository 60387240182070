import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router";
import decode from "jwt-decode";

import * as actionType from "../../constants/actionTypes";
import { createCourse } from "../../api";

export default function Newcourse(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    history.push("/");

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location, user?.token]);

  const handleSubmit = async () => {
    const subject = props.match.params.subject;
    try {
      const { data } = await createCourse({ content, title, subject });
      history.push(`/${data.subject.name}/${data._id}`);
    } catch (err) {
      console.log("EWWW");
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <div position="static" color="inherit">
      {user?.result ? (
        <React.Fragment>
          <TextField
            id="title"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            value={content}
            onChange={setContent}
          />
          <br></br>
          <br></br>
          <br></br>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </React.Fragment>
      ) : (
        <div>
          <Redirect to="/"></Redirect>
        </div>
      )}
    </div>
  );
}
