import { List, ListItem, ListItemText } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getCourses } from "../../api";
import ReactPaginate from "react-paginate";

import "./CourseList.css";

export function Courselist(props) {
  const [list, setList] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const [pageNumber, setPageNumber] = useState(0);

  const coursesPerPage = 5;
  const pagesVisited = pageNumber * coursesPerPage;

  const displayCourses = list
    .slice(pagesVisited, pagesVisited + coursesPerPage)
    .map((course) => {
      return (
        <div>
          <ListItem
            button
            key={course.title}
            onClick={() => {
              history.push(location.pathname + `/${course._id}`);
            }}
          >
            <ListItemText>
              <h4>{course.title}</h4>
            </ListItemText>
          </ListItem>
          <hr />
        </div>
      );
    });

  useEffect(() => {
    async function getAllCourses() {
      try {
        let response = await getCourses(props.subject);
        setList(response.data);
      } catch (err) {
        history.push("/404");
      }
    }

    getAllCourses();
  }, [props.subject, history]);

  const pageCount = Math.ceil(list.length / coursesPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <List>
      {displayCourses}
      <br></br>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        activeClassName={"paginationActive"}
      ></ReactPaginate>
    </List>
  );
}
