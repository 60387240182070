import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "./components/Header/Header";
import Body from "./components/Body/Body";
import avatar from "./avatar.jpeg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Subject from "./components/Subject/Subject";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton } from "@material-ui/core";

import { SIGN_OUT } from "./actions/index";
import Navbar from "./components/Navbar/Navbar";
import Auth from "./components/Auth/Auth";
import NewSubject from "./components/NewSubject/NewSubject";
import Newcourse from "./components/NewCourse/NewCourse";
import Course from "./components/Course/Course";
import EditCourse from "./components/EditCourse/EditCourse";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import { getSubjects } from "./api";
import Homepagebody from "./components/HomepageBody/HomepageBody";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  // toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [subjects, setSubjects] = useState(["HELLo"]);
  const dispatch = useDispatch();

  const isSignedIn = useSelector((state) => state.logIn);

  useEffect(() => {
    async function getAllSubjects() {
      const { data } = await getSubjects();
      setSubjects(data);
    }
    getAllSubjects();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div>
        <Link to="/">
          <img
            className="Image"
            src={avatar}
            style={{
              width: "50px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "5%",
            }}
          />
          {/* <h2 style={{textAlign:'center'}}>MTLCHRISLEE</h2> */}
        </Link>
      </div>
      <Divider />
      <List>
        {subjects.map((subject) => (
          <ListItem
            button
            key={subject.name}
            component={Link}
            to={`/${subject.name}`}
          >
            <ListItemText primary={subject.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      {isSignedIn ? (
        <div>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "50%" }}
            component={Link}
            to={"/subject"}
          >
            New subject
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ width: "50%" }}
            onClick={() => dispatch({ type: SIGN_OUT })}
          >
            Sign Out
          </Button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
              <Navbar></Navbar>
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.toolbar} />
          <Switch>
            <Route path="/" exact>
              <Header title="MTLCHRISLEE"></Header>
              <h2 style={{ fontStyle: "italic", display: "inline" }}>
                The best way to learn is to teach
              </h2>
              <h5 style={{ display: "inline" }}> - Frank Oppenheimer</h5>
              <hr />
              <Homepagebody></Homepagebody>
              {/* <Body content={""}></Body> */}
            </Route>

            <Route path="/tkdlsdls" exact component={Auth} />
            <Route path="/subject" exact component={NewSubject} />
            <Route path="/404" exact component={PageNotFound} status={404} />
            <Route path="/:subject" exact component={Subject} />
            <Route path="/:subject/new" exact component={Newcourse} />
            <Route path="/:subject/:id" exact component={Course} />
            <Route path="/:subject/:id/edit" exact component={EditCourse} />
          </Switch>
        </main>
      </Router>
    </div>
  );
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default App;
