import React from "react";

import pageNotFound from "./pageNotFound.jpg";

export default function Pagenotfound(props) {
  return (
    <React.Fragment>
      <h1>Page Not Found</h1>
      <img src={pageNotFound}></img>
    </React.Fragment>
  );
}
